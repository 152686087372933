import React, { useState, createContext, useContext } from "react";

const defaultState = {
  layoutMount: false,
  firstVisitToAboutPage: true,
  firstVisitToWritingPage: true,
};

const WebMountContext = createContext(defaultState);

export const WebMountProvider = ({ children }) => {
  const [isLayoutMounted, setLayoutMounted] = useState(defaultState.layoutMount);
  const [firstVisitToAboutPage, setFirstVisitToAboutPage] = useState(
    defaultState.firstVisitToAboutPage
  );
  const [firstVisitToWritingPage, setFirstVisitToWritingPage] = useState(
    defaultState.firstVisitToWritingPage
  );

  const contextValues = {
    isLayoutMounted,
    setLayoutMounted,
    firstVisitToAboutPage,
    setFirstVisitToAboutPage,
    firstVisitToWritingPage,
    setFirstVisitToWritingPage,
  };

  return <WebMountContext.Provider value={contextValues}>{children}</WebMountContext.Provider>;
};

export const useWebMountContext = () => {
  const context = useContext(WebMountContext);
  if (context === undefined || context === null) {
    throw new Error(`useSimpleContext must be called within SimpleContextProvider`);
  }
  return context;
};
