import * as React from "react";
import { Link } from "gatsby";
import * as styles from "./NavigationDesktop.module.scss";
import ThemeToggle from "../ThemeToggle/ThemeToggle";

const mainNavItems = [
  { url: "/about", name: "About" },
  { url: "/writing", name: "Writing" },
  { url: "/", name: "Sufyan Azman" },
  { url: "/work", name: "Work" },
];

export const NavigationDesktop = ({ tweenRef }) => {
  const showThemeToggle = typeof window === "undefined" ? null : <ThemeToggle />;

  return (
    <nav className={styles.nav} ref={tweenRef}>
      <ul className={styles.ul}>
        {mainNavItems.map((item) => (
          <li key={item.name}>
            <Link to={item.url} activeClassName={styles.active} partiallyActive={true}>
              {item.name}
            </Link>
          </li>
        ))}
        <li>{showThemeToggle}</li>
      </ul>
    </nav>
  );
};
