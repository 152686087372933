import React, { useRef, useState } from "react";
import * as styles from "./NavigationMobile.module.scss";
import { Link } from "gatsby";
import ThemeToggle from "../ThemeToggle/ThemeToggle";

const Hamburger = ({ showNav, inputRef }) => {
  return (
    <>
      <input
        onChange={showNav}
        className={styles.checkbox}
        type="checkbox"
        name=""
        id="hamburger"
        ref={inputRef}
      />
      <label htmlFor="hamburger" className={styles.hamburger}>
        <span className={styles.topBun}></span>
        <span className={styles.midPatty}></span>
        <span className={styles.bottomBun}></span>
      </label>
    </>
  );
};

const mainNavItems = [
  { url: "/about", name: "About" },
  { url: "/writing", name: "Writing" },
  { url: "/work", name: "Work" },
];

export const NavigationMobile = () => {
  const [show, setShow] = useState(false);
  const linkRef = useRef();
  const inputRef = useRef();

  function showNav() {
    setShow(!show);
  }

  function hideNav() {
    inputRef.current.checked = false;
  }

  const showThemeToggle = typeof window === "undefined" ? null : <ThemeToggle />;
  return (
    <div className={styles.NavMobile}>
      <Link to="/" className={styles.logo}>
        Sufyan Azman
      </Link>
      <Hamburger showNav={showNav} inputRef={inputRef} />
      <div className={styles.overlayNav}>
        <ul ref={linkRef}>
          <li style={{ zIndex: `12` }}>{showThemeToggle}</li>
          {mainNavItems.map((item, index) => (
            <li key={item.name}>
              <div role="button" tabIndex={index} onClick={hideNav} onKeyPress={hideNav}>
                <Link to={item.url}>{item.name}</Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
