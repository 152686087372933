import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import * as styles from "./Layout.module.scss";
import { useWebMountContext } from "../../context/PageLoadContext";

import NavigationHeader from "../NavigationHeader/NavigationHeader";
import Footer from "./Footer/Footer";
import "../../styles/main.scss";

import "@fontsource/vollkorn/900.css";
import "@fontsource/vollkorn/700.css";
import "@fontsource/vollkorn/400.css";
import "@fontsource/vollkorn";
import "@fontsource/dm-sans";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/700.css";

const Layout = ({ children, location }) => {
  const { isLayoutMounted, setLayoutMounted } = useWebMountContext();
  const mainRef = useRef();

  useEffect(() => {
    setLayoutMounted(true);
  }, [setLayoutMounted]);

  useEffect(() => {
    gsap.from("body", { autoAlpha: 0 });
  }, []);

  const showFooter = () => {
    const URLWithoutFooterList = ["/"];
    if (!URLWithoutFooterList.includes(location.pathname)) {
      return <Footer />;
    }
  };

  return (
    <div className={styles.page_wrapper}>
      <NavigationHeader location={location} isLayoutMounted={isLayoutMounted} />
      <main ref={mainRef} className={styles.main}>
        {children}
      </main>
      {showFooter()}
    </div>
  );
};

export default Layout;
