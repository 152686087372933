import React, { useState } from "react";
import * as styles from "./ThemeToggle.module.scss";
import SVGMoon from "../SVG/SVG_Moon";
import SVGSun from "../SVG/SVGSun";

const ThemeToggle = () => {
  const [theme, setTheme] = useState(window.__theme);
  const icon = theme === "dark" ? <SVGMoon /> : <SVGSun />;

  function toggleLightDark(e) {
    if (window.__theme === "dark") {
      window.__setPreferredTheme("light");
      setTheme("light");
    } else {
      window.__setPreferredTheme("dark");
      setTheme("dark");
    }
  }
  return (
    <button
      aria-label={`Active ${theme} mode`}
      onClick={toggleLightDark}
      className={`${styles.btn} ${styles.btn_noFill}`}
    >
      <div className={styles.icon_container}>{icon}</div>
    </button>
  );
};

export default ThemeToggle;
