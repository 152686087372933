import React, { useEffect, useRef } from "react";
import * as styles from "./Footer.module.scss";

import { IconButton } from "../../Button/Button";
import { doStaggerAnimation } from "../../../animations/twn_doStaggerAnimation";

const Footer = () => {
  const footerRef = useRef();
  useEffect(() => {
    doStaggerAnimation(footerRef.current, "li", "bottom bottom");
  }, []);
  return (
    <footer className={styles.footer} ref={footerRef}>
      <h2>Let's Connect!</h2>
      <p>
        I genuinely embrace weird, honest and vulnerable conversations. If you're looking for a UI
        developer, have a question, or just hoping to connect and cry together, feel free to reach
        me.
      </p>
      <address>
        <ul>
          <li key="email">
            <IconButton
              haveHoverInteraction
              haveShadow
              iconName="email"
              url="mailto:sufyazman@gmail.com"
            />
          </li>
          <li key="github">
            <IconButton
              haveHoverInteraction
              haveShadow
              iconName="github"
              url="https://github.com/droopydev"
            />
          </li>
          <li key="linkedin">
            <IconButton
              haveHoverInteraction
              haveShadow
              iconName="linkedin"
              url="https://www.linkedin.com/in/sufyazman/"
            />
          </li>
          <li key="twitter">
            <IconButton
              haveHoverInteraction
              haveShadow
              iconName="twitter"
              url="https://twitter.com/sufyazman"
            />
          </li>
        </ul>
      </address>
      <p className={styles.footer_copyright}>Sufyan © 2022</p>
    </footer>
  );
};

export default Footer;
