import * as React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import "./Code.scss";

export const CodeBlock = (props) => {
  const className = props.children.props.className || "";
  const code = props.children.props.children.trim();
  const language = className.replace(/language-/, "");
  const file = props.children.props.file;
  return (
    <Highlight {...defaultProps} code={code} language={language} theme={undefined}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className="codeblock">
          {file && (
            <div className="codeblock__header__wrapper">
              <div className="codeblock__meta-file__wrapper">{file}</div>
            </div>
          )}
          <pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        </div>
      )}
    </Highlight>
  );
};

export const CodeInline = (props) => {
  const className = props.lang || "";
  const code = props.children.props.children.trim();
  const language = className.replace(/language-/, "");

  return (
    <Highlight {...defaultProps} language={language} code={code} theme={undefined}>
      {({ className, defaultProps, style, tokens, getLineProps, getTokenProps }) => {
        return (
          <code className={`codeinline ${className}`}>
            {tokens.map((line, i) => (
              <span {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </span>
            ))}
          </code>
        );
      }}
    </Highlight>
  );
};
