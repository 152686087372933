import React, { useEffect, useRef } from "react";
import * as styles from "./NavigationHeader.module.scss";

import { NavigationMobile } from "../NavigationMobile/NavigationMobile.js";
import { NavigationDesktop } from "../NavigationDesktop/NavigationDesktop.js";
import { doNavigationAnimation } from "../../animations/t1_NavigationHeader";

const NavigationHeader = ({ location, isLayoutMounted }) => {
  const HeaderRef = useRef();
  const NavRef = useRef();

  useEffect(() => {
    if (!isLayoutMounted && location.pathname === "/") {
      doNavigationAnimation(HeaderRef.current, NavRef);
    }
  }, [location, isLayoutMounted]);

  return (
    <header className={styles.nav_container} ref={HeaderRef}>
      <div className={styles.nav_desktop_container}>
        <NavigationDesktop tweenRef={NavRef} />
      </div>
      <div className={styles.nav_mobile_container}>
        <NavigationMobile />
      </div>
    </header>
  );
};

export default NavigationHeader;
