import { gsap } from "gsap";

export const doNavigationAnimation = (Header, Navigation) => {
  const timeline = gsap.timeline();
  const NavSelector = gsap.utils.selector(Navigation);

  // Header comes down.
  // --------------------------
  timeline
    .from(Header, { y: -50, opacity: 0 }, 0)

    // Links staggers down.
    // --------------------------
    .from(
      NavSelector("li"),
      {
        y: -50,
        opacity: 0,
        stagger: "0.25",
        autoAlpha: 0,
      },

      // Animates 0.5 seconds before the end of the previous animation.
      ">-0.5"
    );
};
