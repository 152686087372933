import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const doStaggerAnimation = (sectionEl, selector, triggerPos) => {
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: sectionEl,
      start: triggerPos ? triggerPos : "top center",
    },
  });
  // GSAP utils to select child elements
  const q = gsap.utils.selector(sectionEl);

  timeline
    // Child elements with matched selector appears staggerly
    // -----------------------------------------------------------
    .from(q(selector), {
      opacity: 0,
      y: 20,
      stagger: {
        each: 0.1,
        from: 0,
        grid: "[1,5]",
      },
    });
};
