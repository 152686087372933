import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { CodeBlock } from "../components/Code/Code";
import { WebMountProvider } from "../context/PageLoadContext";

const components = {
  pre: (props) => <CodeBlock {...props} />,
  wrapper: ({ children }) => <>{children}</>,
};

export const wrapRootElement = ({ element }) => {
  return (
    <WebMountProvider>
      <MDXProvider components={components}>{element}</MDXProvider>
    </WebMountProvider>
  );
};
