exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-frontend-mentor-challenge-js": () => import("./../../../src/pages/work/frontend-mentor-challenge.js" /* webpackChunkName: "component---src-pages-work-frontend-mentor-challenge-js" */),
  "component---src-pages-work-grokking-technical-questions-js": () => import("./../../../src/pages/work/grokking-technical-questions.js" /* webpackChunkName: "component---src-pages-work-grokking-technical-questions-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-lta-js": () => import("./../../../src/pages/work/lta.js" /* webpackChunkName: "component---src-pages-work-lta-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-template-blog-posts-js": () => import("./../../../src/templates/template-blog-posts.js" /* webpackChunkName: "component---src-templates-template-blog-posts-js" */),
  "component---src-templates-template-frontend-mentor-challenge-js": () => import("./../../../src/templates/template-frontend-mentor-challenge.js" /* webpackChunkName: "component---src-templates-template-frontend-mentor-challenge-js" */),
  "component---src-templates-template-grokking-technical-questions-js": () => import("./../../../src/templates/template-grokking-technical-questions.js" /* webpackChunkName: "component---src-templates-template-grokking-technical-questions-js" */)
}

