import * as React from "react";
import { Link } from "gatsby";
import * as styles from "./Button.module.scss";

import mailSvg from "../../images/icons/lineIcon/mail.svg";
import githubSvg from "../../images/icons/lineIcon/github.svg";
import linkedinSvg from "../../images/icons/lineIcon/linkedin.svg";
import femSvg from "../../images/icons/lineIcon/fem.svg";
import arrowSvg from "../../images/icons/lineIcon/external-link.svg";
import twitterSvg from "../../images/icons/lineIcon/twitter.svg";

export function IconButton(props) {
  const { iconName, url, haveHoverInteraction, haveShadow, className } = props;

  let IconSource;
  switch (iconName) {
    case "email":
      IconSource = mailSvg;
      break;
    case "github":
      IconSource = githubSvg;
      break;
    case "linkedin":
      IconSource = linkedinSvg;
      break;
    case "frontendmentor":
      IconSource = femSvg;
      break;
    case "twitter":
      IconSource = twitterSvg;
      break;
    default:
      IconSource = arrowSvg;
  }

  const anchorClassname = `${styles.iconButton_container} ${
    haveHoverInteraction ? styles.hoverUp : ""
  }`;

  return (
    <div className={`${styles.iconButton_wrapper} ${className ? className : ""}`}>
      <a className={anchorClassname} href={url}>
        <IconSource />
      </a>
      {haveShadow ? <div className={styles.shadow}></div> : ""}
    </div>
  );
}

export function Button(props) {
  const { url, children, to } = props;

  // External Link
  if (url) {
    return (
      <a href={url} className={`${styles.button}`}>
        {children}
      </a>
    );
  }

  // Internal Link With Gatsby Plugin
  if (to) {
    return (
      <Link to={to} className={`${styles.button}`}>
        {children}
      </Link>
    );
  }

  return <button className={styles.button}>{children}</button>;
}
